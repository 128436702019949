import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaHardwareChecker } from '@provider-hooks';
import { setIsCameraPermOk, setIsMicPermOk } from '@provider-reducers/mediaPermissionsSlice';

export function useMediaStateChange(): {
  reloadCam: boolean;
  setReloadCam: (value: boolean) => void;
  isMediaStateGranted: boolean;
  triggerCameraAndMicCheck: () => void;
  triggerCameraCheck: () => void;
  triggerMicCheck: () => void;
} {
  const dispatch = useDispatch();
  const [reloadCam, setReloadCam] = useState<boolean>(false);
  const [micPermissionState, setMicPermissionStatus] = useState<PermissionState>();
  const [camPermissionState, setCamPermissionStatus] = useState<PermissionState>();

  const props = useMediaHardwareChecker({
    onMicPermissionStatusChange: status => {
      setMicPermissionStatus(prevState => {
        if (prevState === 'granted' && status.state === 'denied') {
          setReloadCam(true);
        }
        return status.state;
      });
      dispatch(setIsMicPermOk(status.state === 'granted'));
    },
    onCamPermissionStatusChange: status => {
      setCamPermissionStatus(prevState => {
        if (prevState === 'denied' && status.state === 'granted') {
          setReloadCam(true);
        }
        return status.state;
      });
      dispatch(setIsCameraPermOk(status.state === 'granted'));
    }
  });

  return {
    reloadCam,
    setReloadCam,
    isMediaStateGranted: camPermissionState === 'granted' && micPermissionState === 'granted',
    ...props
  };
}
