import { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCheckAttemptCount,
  selectCheckInProgress,
  selectInitialCamCheckComplete,
  selectInitialMicCheckComplete,
  selectShouldCheckCamera,
  selectShouldCheckMic
} from '@provider-selectors/mediaPermissions';
import {
  cameraOrMicCheckBegun,
  cameraOrMicCheckCompleted,
  initialCameraPermCheckComplete,
  initialMicPermCheckComplete,
  setIsCameraPermOk,
  setIsMicPermOk,
  shouldCheckCameraPerm,
  shouldCheckMicAndCameraPerm,
  shouldCheckMicPerm
} from '@provider-reducers/mediaPermissionsSlice';

const cameraPermName = 'camera' as PermissionName;
const microPermName = 'microphone' as PermissionName;

interface UseMediaHardwareCheckerOptions {
  onCamPermissionStatusChange?: (status: PermissionStatus) => void;
  onMicPermissionStatusChange?: (status: PermissionStatus) => void;
}

export function useMediaHardwareChecker(options?: UseMediaHardwareCheckerOptions): {
  triggerCameraAndMicCheck: () => void;
  triggerCameraCheck: () => void;
  triggerMicCheck: () => void;
} {
  const shouldCheckMic = useSelector(selectShouldCheckMic);
  const shouldCheckCamera = useSelector(selectShouldCheckCamera);
  const checkAttemptCount = useSelector(selectCheckAttemptCount);
  const initialCamCheckComplete = useSelector(selectInitialCamCheckComplete);
  const initialMicCheckComplete = useSelector(selectInitialMicCheckComplete);
  const checkInProgress = useSelector(selectCheckInProgress);

  const dispatch = useDispatch();
  const prevCheckAttemptCount = useRef(checkAttemptCount);

  // useEffect to manage the *initial* permissions check. Ignores `checkAttemptCount` so that
  // we can avoid multiple polls on initialization.
  useEffect(() => {
    const isMediaHardwareAccessible = async (constraints: MediaStreamConstraints): Promise<boolean> => {
      dispatch(cameraOrMicCheckBegun());
      // Used to enable a Chrome specific workaround for mobile Chrome
      const isChrome = navigator.userAgent.match(/Chrome\/\d+/) !== null;
      let hardwareCaptured = false;
      try {
        let videoGranted = false;
        let audioGranted = false;

        // Additional check in Chrome to avoid grabbing a track unnecessarily. May prevent some
        // intermittent mobile Chrome media issues
        if (isChrome) {
          videoGranted = (await navigator.permissions?.query({ name: cameraPermName }))?.state === 'granted';
          audioGranted = (await navigator.permissions?.query({ name: microPermName }))?.state === 'granted';
        }
        //console.log(`Checking for hardware with constraints: ${JSON.stringify(constraints)}`);
        if ((constraints.video && !videoGranted) || (constraints.audio && !audioGranted)) {
          const mediaStream = await navigator.mediaDevices?.getUserMedia(constraints);
          if (mediaStream) {
            mediaStream.getTracks().forEach((track: MediaStreamTrack) => {
              if (track.readyState === 'live') {
                hardwareCaptured = true;
                track.stop();
                mediaStream.removeTrack(track);
              }
            });
          }
        } else {
          hardwareCaptured = true;
        }
      } catch (error) {
        console.log(
          `Error obtaining hardware with constraints ${JSON.stringify(constraints)}: error: ${JSON.stringify(
            error,
            Object.getOwnPropertyNames(error)
          )}`
        );
      }
      dispatch(cameraOrMicCheckCompleted());
      return hardwareCaptured;
    };

    const checkMicrophoneHardwareState = async () => {
      console.log('(Permissions) Checking mic state');
      const micAccessible = await isMediaHardwareAccessible({ video: false, audio: true });
      dispatch(setIsMicPermOk(micAccessible));
      !initialMicCheckComplete && dispatch(initialMicPermCheckComplete());
    };

    const checkCameraHardwareState = async () => {
      console.log('(Permissions) Checking camera state');
      const camAccessible = await isMediaHardwareAccessible({
        video: {
          width: { min: 640, ideal: 1280, max: 1280 },
          height: { min: 360, ideal: 720, max: 720 },
          aspectRatio: 16 / 9
        },
        audio: false
      });

      dispatch(setIsCameraPermOk(camAccessible));
      !initialCamCheckComplete && dispatch(initialCameraPermCheckComplete());
    };

    const checkCameraAndMicrophoneHardwareState = async () => {
      console.log('(Permissions) Checking camera and mic state');
      const camAndMicAccessible = await isMediaHardwareAccessible({
        video: {
          width: { min: 640, ideal: 1280, max: 1280 },
          height: { min: 360, ideal: 720, max: 720 },
          aspectRatio: 16 / 9
        },
        audio: true
      });
      if (camAndMicAccessible) {
        dispatch(setIsCameraPermOk(true));
        dispatch(setIsMicPermOk(true));
      } else {
        dispatch(setIsCameraPermOk(false));
        dispatch(setIsMicPermOk(false));

        //in case one of the permissions is denied, the other should be checked
        await checkMicrophoneHardwareState();
        await checkCameraHardwareState();
      }

      !initialCamCheckComplete && dispatch(initialCameraPermCheckComplete());
      !initialMicCheckComplete && dispatch(initialMicPermCheckComplete());
    };

    // Start of useEffect logic

    if (prevCheckAttemptCount.current === checkAttemptCount) {
      return;
    }
    prevCheckAttemptCount.current = checkAttemptCount;

    // If we require both permissions, check both simultaneously, triggering
    // a single popup
    if (shouldCheckCamera && shouldCheckMic) {
      checkCameraAndMicrophoneHardwareState();
    } else if (shouldCheckMic) {
      checkMicrophoneHardwareState();
    } else if (shouldCheckCamera) {
      checkCameraHardwareState();
    }
  }, [
    dispatch,
    checkAttemptCount,
    checkInProgress,
    shouldCheckMic,
    shouldCheckCamera,
    initialCamCheckComplete,
    initialMicCheckComplete
  ]);

  useEffect(() => {
    let cameraPerm: null | PermissionStatus = null;
    let micPerm: null | PermissionStatus = null;

    const handleCamPermissionChange = (event: Event) => {
      const permission = event.currentTarget as PermissionStatus;
      if (options?.onCamPermissionStatusChange) options.onCamPermissionStatusChange(permission);
    };

    const handleMicPermissionChange = (event: Event) => {
      const permission = event.currentTarget as PermissionStatus;
      if (options?.onMicPermissionStatusChange) options.onMicPermissionStatusChange(permission);
    };

    const checkPermissions = async () => {
      const isChrome = navigator.userAgent.match(/Chrome\/\d+/) !== null;

      if (!isChrome) {
        return;
      }

      try {
        cameraPerm = await navigator.permissions.query({ name: cameraPermName });
        micPerm = await navigator.permissions.query({ name: microPermName });

        cameraPerm.addEventListener('change', handleCamPermissionChange);
        micPerm.addEventListener('change', handleMicPermissionChange);
      } catch (e) {
        console.log('media permission status error', e);
      }
    };

    checkPermissions();

    return () => {
      if (cameraPerm) cameraPerm.removeEventListener('change', handleCamPermissionChange);
      if (micPerm) micPerm.removeEventListener('change', handleMicPermissionChange);
    };
  }, []);

  const triggerCameraCheck = useCallback(() => {
    dispatch(shouldCheckCameraPerm());
  }, [dispatch]);

  const triggerMicCheck = useCallback(() => {
    dispatch(shouldCheckMicPerm());
  }, [dispatch]);

  const triggerCameraAndMicCheck = useCallback(() => {
    dispatch(shouldCheckMicAndCameraPerm());
  }, [dispatch]);

  return {
    triggerCameraAndMicCheck,
    triggerCameraCheck,
    triggerMicCheck
  };
}
